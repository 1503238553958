import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Breadcrumbs = makeShortcode("Breadcrumbs");
const InternalContent = makeShortcode("InternalContent");
const ExternalContent = makeShortcode("ExternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Breadcrumbs items={[{
      name: 'Announcements',
      to: '/whats-new/announcements'
    }, {
      name: 'Canvas v7 Announcements'
    }]} mdxType="Breadcrumbs" />
    <h2 {...{
      "id": "canvas-kit-740-release"
    }}>{`Canvas Kit 7.4.0 Release`}</h2>
    <p>{`Canvas Kit 7.4.0 is now available. In this update, we added support to display a section header
title in the Menu component. This allows menu items to be grouped logically. These contextual
headers in Menus are not limited to icon-only button menus and can also be used for menu targets
with a text-based button. In the following example, we show how "Newest on Top" and "Oldest on Top"
are related to sorting in the menu and tied to the sorting icon button.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "324px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/60208f7d39a75ad5a0b3e8e88e7d2a4e/67580/7.4-menu-selection-header-title-image.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "139.19753086419752%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAcCAYAAABh2p9gAAAACXBIWXMAAAsTAAALEwEAmpwYAAADHUlEQVR42p1Wa3eaUBDk//+s9kOTxmejUQM0KmgEg6IoynM6ewFr1KjtPWfPIvcy7OzOLmpZliGOY0Q08bKSNEWe56iW47owTBOGYWIynR7OXVpaFMXYbLfYbDbYR5ECSk8ABWC/3yuLeEaCuAoYhjuY5m/lLwFWS+6JCeBXpkXl27eMMNyG8hgBEz6YqWtl+Q07jjAnapSkSLgRZTk9EKUZUm7eZSUjSUvAoLSMN5LFBzM/BdwZ/Tv9O3J7dNusN+QfblHIJMFut4Mmb8ntMYx2G51WCy/0XXrE0XWL9kRhtYcmmDykYoxUSwiYWSO4pgHLMOCMhrB0HRkrz1ciD0OaXIfntuf+m6HymDBty6UPzfPXiBYe4M2BtQ8EtNUSIJVsNkUuaZjT3m1gNvlsvJf7i5JyClGMNhzbDDVDtz/AY6OJxvMzuoMBzNEIb5aNWvsXnPn8IJvjJfnfkEUFGMcJtKd6A1vSiynY9WqFle8rkYuMpHIrf8Xr7V/AYxPtpanak2deX3Voztw7dMhpBGl5+J4lel5IDqczV1HWDR3fvn1HrV5XffvKwvSYhkazebFrDt1zBPjhLYoIJZlZlqqwVU+zc0JWVyj7TIF0TcEwvxqht1hSh6QlCZU+rGifRiS/r02YA+CSgDKaBLQ/6Ct6vV4PrVYbTV4/1WpqZOm6gX6/j6YI/mqEpGzZNilGiKn6ICjobjkkgiBQv6WdxGK21mq9PmNSsZH9dSC9zAPSh9dWoZLiQcnjqcmWFLZovSRVN/oUs1CqU5fdbhfPnQ6pt9Ch11nxW+swHISuIC+ZUMuyMGSHTDnm5+yO2WwGx3Hguu5NQMGxJ5OS8j8I+KslQSnKo/FYyUaEXKvV8fj4E2PeOx7514R9BuiSmpRcciCi9tm78iH67wgrynkph6KS2V1RHU+gA6BEpqpM4f54eFCiFu953sWR9Wl88cWi1U+AStScYzHHulCWHpZxdu+kOYswK78FX32Db1k1c6Swkjqt+/KiRLzhEDX4sXfcOaNk+zFS+fBLG8o/i5Cild/i5Wx1rXwo7blXL/gDMw2A1zASBroAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Image of the updated menu component with the new title added and boxed in red to show what changed on the component.",
            "title": "Image of the updated menu component with the new title added and boxed in red to show what changed on the component.",
            "src": "/static/60208f7d39a75ad5a0b3e8e88e7d2a4e/67580/7.4-menu-selection-header-title-image.png",
            "srcSet": ["/static/60208f7d39a75ad5a0b3e8e88e7d2a4e/67580/7.4-menu-selection-header-title-image.png 324w"],
            "sizes": "(max-width: 324px) 100vw, 324px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <InternalContent mdxType="InternalContent">
      <p>{`Additionally in Canvas Kit 7.4.0, we added support for opening stories in Tesseract. This change
enables the “Open in Tesseract” button in Anthology.`}</p>
      <p>{`To access these changes, please upgrade to
`}<a parentName="p" {...{
          "href": "https://github.com/Workday/canvas-kit/releases/tag/v7.4.0"
        }}>{`Canvas Kit 7.4.0`}</a>{`.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`To access this change, please upgrade to
`}<a parentName="p" {...{
          "href": "https://github.com/Workday/canvas-kit/releases/tag/v7.4.0"
        }}>{`Canvas Kit 7.4.0`}</a>{`.`}</p>
    </ExternalContent>
    <hr></hr>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "september-2022-design-asset-release"
      }}>{`September 2022 Design Asset Release`}</h2>
      <p>{`The Design Asset September 2022 Release is now available for use through the Canvas Assets Figma
library for designers and NPM, Artifactory, or SAS (on all environments) for developers.`}</p>
      <p>{`The following system icons were released with this update:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`wd-icon-package-arrow-down-icon.svg`}</inlineCode>{` (`}<inlineCode parentName="li">{`packageArrowDownIcon`}</inlineCode>{` when using our
`}<a parentName="li" {...{
            "href": "/styles/assets/system-icons#web-examples-1"
          }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`)`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`wd-icon-my-referrals-icon.svg`}</inlineCode>{` (`}<inlineCode parentName="li">{`myReferralsIcon`}</inlineCode>{`)`}</li>
      </ul>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "384px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/c19a407d84d304fc2231dfced9429998/804b2/sept-da-sys-icons.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABZklEQVR42q1Sa0sCURD130ffIgsqNSIVjLTC1LRELBPWXN/v9YEI9am0Mh+r7Z7u3M2r2wP60MCwhzN3zt57ZixgoeP/wqLrhlwqU8DOgQf7bh/PXYYv40leW5wZvo34uXqzIwQIE0c1Lrg46DkJwu48FoIOlxeHR2d47A9Es5wvY21jD+VaU3CEiaOaECxW6ti0OeE9j8BzGmIZhC8QgdXmQqmqiGal1YUkFzhud3s8KYijmhCU5Dwuojec0DQd75rGsT8cQ0LKfPKa6ev1R/gFVjkhSB6EotecUGczzOZzjtPZIip143mavhzdVFWx7XDxJGwaiiFYRDiW+Pa33v0D1JWGZqcLOWd41R8844kl95ZxVBOCZKjV7kTsNoXAVRzpXAmj8YTdwG2aaLZQwbrVhlJt6Sth4qgmBF9eh1xUYk9P3mVRVdpMcIwtuxvVRks00zZIP6yN9HVtfovxZGqy4C+L/QEvKeHwWJrKZwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Two system icons: The first is a package with an arrow pointing down, and the second is a small, centered user icon with border lines on each corner.",
              "title": "Two system icons: The first is a package with an arrow pointing down, and the second is a small, centered user icon with border lines on each corner.",
              "src": "/static/c19a407d84d304fc2231dfced9429998/804b2/sept-da-sys-icons.png",
              "srcSet": ["/static/c19a407d84d304fc2231dfced9429998/1efb2/sept-da-sys-icons.png 370w", "/static/c19a407d84d304fc2231dfced9429998/804b2/sept-da-sys-icons.png 384w"],
              "sizes": "(max-width: 384px) 100vw, 384px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p>{`To upgrade to the latest, bump `}<inlineCode parentName="p">{`@workday/canvas-system-icons-web`}</inlineCode>{` to `}<inlineCode parentName="p">{`3.0.3`}</inlineCode>{`. This release will not
impact users as they are net-new assets with no changes to previously released assets.`}</p>
      <p>{`For more information on these assets, please reference the
`}<a parentName="p" {...{
          "href": "https://ghe.megaleo.com/design/design-assets"
        }}>{`Design Asset Repo`}</a>{`.`}</p>
      <hr></hr>
    </InternalContent>
    <h2 {...{
      "id": "canvas-kit-731-release-update"
    }}>{`Canvas Kit 7.3.1 Release Update`}</h2>
    <p>{`The Canvas Kit 7.3.1 update was released on September 9, 2022.`}</p>
    <p>{`This release fixes three spacing-related bugs to our `}<inlineCode parentName="p">{`Popup`}</inlineCode>{` component that we introduced in Canvas
Kit 7.0.0:`}</p>
    <ul>
      <li parentName="ul">{`Focused elements inside `}<inlineCode parentName="li">{`Popup.Body`}</inlineCode>{` were cut off due to the built-in vertical overflow. There was
a small portion of the focus ring that went beyond the container.`}</li>
      <li parentName="ul">{`The space between `}<inlineCode parentName="li">{`Popup.Heading`}</inlineCode>{` and `}<inlineCode parentName="li">{`Popup.Body`}</inlineCode>{` was 16px instead of 24px as specified in the
design spec.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Popup.Card`}</inlineCode>{` caused spacing issues when `}<inlineCode parentName="li">{`Popup.CloseIcon`}</inlineCode>{` was used.`}</li>
    </ul>
    <p>{`Because `}<inlineCode parentName="p">{`Modal`}</inlineCode>{`, `}<inlineCode parentName="p">{`Dialog`}</inlineCode>{`, and `}<inlineCode parentName="p">{`Toast`}</inlineCode>{` are built using `}<inlineCode parentName="p">{`Popup`}</inlineCode>{`, these components are also affected.
The changes to each of these components are described below.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Popup.Body`}</inlineCode>{` now has `}<inlineCode parentName="li">{`8px`}</inlineCode>{` of padding. This addresses the issue where focus rings were being
clipped within the Body due to its built-in overflow. This padding allows the focus ring on any
focusable element contained within the Body to be fully visible. The total space between the edge
of the Card and the contents of the Body is still `}<inlineCode parentName="li">{`32px`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Popup.Heading`}</inlineCode>{` now has `}<inlineCode parentName="li">{`8px`}</inlineCode>{` of padding to keep it visually aligned with the updated
`}<inlineCode parentName="li">{`Popup.Body`}</inlineCode>{`. It also now has a `}<inlineCode parentName="li">{`marginBottom`}</inlineCode>{` of `}<inlineCode parentName="li">{`8px`}</inlineCode>{`, bringing the default vertical space
between the Heading and the Body to `}<inlineCode parentName="li">{`24px`}</inlineCode>{` (previously `}<inlineCode parentName="li">{`16px`}</inlineCode>{`) in order to meet the component
spec.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Popup.Card`}</inlineCode>{`’s padding has been reduced by `}<inlineCode parentName="li">{`8px`}</inlineCode>{` from `}<inlineCode parentName="li">{`32px`}</inlineCode>{` to `}<inlineCode parentName="li">{`24px`}</inlineCode>{` to compensate for the
padding added to `}<inlineCode parentName="li">{`Popup.Body`}</inlineCode>{` and `}<inlineCode parentName="li">{`Popup.Heading`}</inlineCode>{` and to preserve the component's existing
spacing. If you've customized the padding for any `}<inlineCode parentName="li">{`Popup, Modal`}</inlineCode>{`, or `}<inlineCode parentName="li">{`Dialog`}</inlineCode>{` subcomponents, you
may need to adjust your padding values to get the same results after upgrading.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Popup.Card`}</inlineCode>{`'s default component is now `}<inlineCode parentName="li">{`Flex`}</inlineCode>{` (previously `}<inlineCode parentName="li">{`Stack`}</inlineCode>{`) so the `}<inlineCode parentName="li">{`spacing`}</inlineCode>{` prop has been
removed. `}<inlineCode parentName="li">{`Stack`}</inlineCode>{` created spacing issues when used with a `}<inlineCode parentName="li">{`Popup.CloseIcon`}</inlineCode>{` which did not behave as
a normal `}<inlineCode parentName="li">{`Stack`}</inlineCode>{` item.
`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/ae933711dd9db8b8e7fac0926cba36e9/76cea/ck-7.3.1-update.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "130.8108108108108%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAYAAAC3g3x9AAAACXBIWXMAAAsTAAALEwEAmpwYAAADzElEQVR42q1VW28aRxTOv06VVGpa5SGV2re8RK2UWnbcghNcB1AcY3C4xDYs4N3lsmBzNQZjYzuV2Su7O1/P7GJiR8BTRvpmZ87snD2c852PR6Ch6zpBw3iswjQtWJYFTdOmdh2GYXg2VVW9veu6WDQeMcbQbJxAUSool2UUCjnUalXIsohqtYxiMY98XqDzqreWpCP68Ni7zO/ex9Qh0Gq5KMsMShkoibSWGCoycFSwIBYmBNuDTGey6OBg36Y7DncJ5rJvIwRyh0Am60CoWsiVLRzIJkpNB0qHoXbKUD+Djx5w3Ad242SrAZfX/+Gk0UG30/bS4TukfBQkQPw7jfqLn1D7/VdIz59h8OolrM0g9I116IE16ME3HozgGvI7l+gOgIFUhJBKo6IouBqNvjrMi/RT/z3CcPU1On+t4CYUgvnhA9RoFGokAjUcnkELv4cQu0GrC7jjL7B1A7bjUDFN3yGfDg9cNNsMNuVEo5zYjGHZEAQHJ8futDDfVJlPnzMm2i3bTzKbvsWpMQ90lj20UK/bs0o/LApNuSwosZj7xXmjWABRbf77nsPsQofzvRfyAFF3gUPmR9igL9o27xpQZ/jgeb5b37cJOf/9hQ7zAsNegiH5iQMeUkniW8xAIm7PbHfniThvBiwuikFRjW8BdexjPH3e3rKZjUMj7nL+3tz4v2R+L1OeRlfnuLjoYzg8w/X1kC5c0P7Mew6HPVxd+bZ+v4vRaEBRLREHl3h3enqOLlFfUU5IHEQSiRrRogGlekyCIJFQVFGp1MiuoFRSKDoHk8lCtaEuISFI7DKkKI9pnr89yintt6Mm4js2EjFGT5fA7S7evbUgSw7R0iHnJkmd6snbPWIDf7y+xMb7GgKbdawGStjb/4ISCcBRhaHogfq9SoyQXKy+maBDNOsNLiHJChWo6WnmzGGWWi/wJIHwDwFEnoaw9TiIyJN3SLzYxu7zKHZ+DiP2S8Rbb/+4iY3fKugPATlbgXAgot1pod1ue8LrOdz/zLDysontf0RsrRSQ3GqgkLiAEB9A2B0gt0OI+ev9aA/rf16jS3I2psj6jSZ6/T7OB4OvEcbjEySTXC1cOJSXeVW845ttMwSDGo7rk8U8jMU0ZFJ+Dqb9v0gXKPkMobcaqY2v2PxnPvgL4FMq6SKd9vWMpG2+0Di+Q9Nk2AyZaDaXdMqnPYecmrMI5427i5MJp42xWG34lEm7WF9TiT4G9apBzn2kU/ps7e95b2tYXdFJP5eIQ7nkN34i7s7AxeLjR50EwqL9w7NMmjjYWxLh9xz/A1xxl2Q/iyiuAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "A color blocked visual diagram of Popup’s padding and margin updates from Canvas Kit 7.0.0 to 7.3.1.",
              "title": "A color blocked visual diagram of Popup’s padding and margin updates from Canvas Kit 7.0.0 to 7.3.1.",
              "src": "/static/ae933711dd9db8b8e7fac0926cba36e9/50383/ck-7.3.1-update.png",
              "srcSet": ["/static/ae933711dd9db8b8e7fac0926cba36e9/1efb2/ck-7.3.1-update.png 370w", "/static/ae933711dd9db8b8e7fac0926cba36e9/50383/ck-7.3.1-update.png 740w", "/static/ae933711dd9db8b8e7fac0926cba36e9/76cea/ck-7.3.1-update.png 799w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></li>
    </ul>
    <p>{`Given the changes above, if you're creating a footer for your `}<inlineCode parentName="p">{`Popup`}</inlineCode>{`, `}<inlineCode parentName="p">{`Modal`}</inlineCode>{`, or `}<inlineCode parentName="p">{`Dialog`}</inlineCode>{` (using
an `}<inlineCode parentName="p">{`HStack`}</inlineCode>{`, for example), you'll need to update it with the following props/styles to align it with
the updated `}<inlineCode parentName="p">{`Heading`}</inlineCode>{` and`}<inlineCode parentName="p">{` Body`}</inlineCode>{`: `}<inlineCode parentName="p">{`padding="xxs" (8px)`}</inlineCode>{` and `}<inlineCode parentName="p">{`marginTop="xxs" (8px)`}</inlineCode>{`.`}</p>
    <p>{`For more information on this update, please reference the
`}<a parentName="p" {...{
        "href": "https://github.com/Workday/canvas-kit/pull/1727"
      }}>{`7.3.1 release note`}</a>{`. To access this change, please
upgrade to `}<a parentName="p" {...{
        "href": "https://github.com/Workday/canvas-kit/releases/tag/v7.3.1"
      }}>{`Canvas Kit 7.3.1.`}</a>{`.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "canvas-kit-731-release"
    }}>{`Canvas Kit 7.3.1 Release`}</h2>
    <p>{`The 7.4.0 release has been delayed and bumped down to a patch release (7.3.1) since it will only
contain fixes for bugs introduced as part of the Canvas v7 release. The 7.3.1 release will contain
the same updates that were originally planned for 7.4.0, but with some minor changes to the approach
to ensure minimal impact to product teams using Popup, Modal, Dialog, and Toast. Canvas Kit 7.3.1
will be released shortly after the 7.3.0 release.`}</p>
    <p>{`Please follow our announcements and support channels for more updates.`}</p>
    <hr></hr>
    <ExternalContent mdxType="ExternalContent">
      <h2 {...{
        "id": "canvas-kit-730-release"
      }}>{`Canvas Kit 7.3.0 Release`}</h2>
      <p>{`Canvas Kit 7.3.0 will be released on `}<strong parentName="p">{`August 31st, 2022`}</strong>{`.`}</p>
      <p>{`This includes:`}</p>
      <ul>
        <li parentName="ul">{`Adding a new Expandable Container component to Canvas Kit. The Expandable Container component
allows users to expand and collapse content with a single header for a more streamlined viewing
experience.`}</li>
        <li parentName="ul">{`Enhancing the Toast component as a compound component. This makes it easier for developers to use
and customize the Canvas Toast component at the sub-component level. You can find the compound
Toast in Canvas Labs package, so teams using the Toast in the Main package won’t be affected by
this update.`}</li>
      </ul>
      <p>{`To access these changes, please upgrade to
`}<a parentName="p" {...{
          "href": "https://github.com/Workday/canvas-kit/releases"
        }}>{`Canvas Kit 7.3.0`}</a>{` once it becomes available.`}</p>
      <hr></hr>
    </ExternalContent>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "canvas-kit-730-and-figma-library-release"
      }}>{`Canvas Kit 7.3.0 and Figma Library Release`}</h2>
      <p>{`Canvas Kit 7.3.0 will be released on `}<strong parentName="p">{`August 31st, 2022`}</strong>{`, alongside updates to the Canvas Web
Figma library. This is a larger update, including updates for developers in Canvas Kit 7.3.0 and
Canvas 7.3.0 Figma library updates.`}</p>
      <p>{`On the `}<strong parentName="p">{`Canvas Kit`}</strong>{` side, this includes:`}</p>
      <ul>
        <li parentName="ul">{`Adding a new Expandable Container component to Canvas Kit. The Expandable Container component
allows users to expand and collapse content with a single header for a more streamlined viewing
experience.`}</li>
        <li parentName="ul">{`Enhancing the Toast component as a compound component. This makes it easier for developers to use
and customize the Canvas Toast component at the sub-component level. You can find the compound
Toast in Canvas Labs package, so teams using the Toast in the Main package won’t be affected by
this update.`}</li>
      </ul>
      <p>{`In `}<strong parentName="p">{`Figma`}</strong>{`, we are updating our Canvas Web library to:`}</p>
      <ul>
        <li parentName="ul">{`Add an Expandable Container component and usage guidance for it to the Canvas Design System
website so users can expand and collapse content with a single header for a more streamlined
experience.`}</li>
        <li parentName="ul">{`Add a new Skeleton component to replace the deprecated Skeleton component from the Canvas
Production library.`}</li>
        <li parentName="ul">{`Add an Avatar component, as well as avatar variations for the Pill and Expandable Container
components`}</li>
        <li parentName="ul">{`Soft deprecate the data variant for Primary Button by adding deprecation titles to this component.
If your team is currently using the data variant, you should switch to a supported component in
Canvas Web before future hard deprecation.`}</li>
      </ul>
      <p>{`This update will not impact existing designs, but teams will need to accept the change or upgrade to
start uptaking the new components. To access these changes, please upgrade to
`}<a parentName="p" {...{
          "href": "https://github.com/Workday/canvas-kit/releases"
        }}>{`Canvas Kit 7.3.0`}</a>{` once it becomes available and
accept the changes to the Figma libraries once released.`}</p>
      <hr></hr>
      <h2 {...{
        "id": "august-2022-design-asset-release"
      }}>{`August 2022 Design Asset Release`}</h2>
      <p>{`The Design Asset August 2022 Release is now available for use through the Canvas Assets Figma
library for designers and NPM, Artifactory, or SAS (on all environments) for developers.`}</p>
      <p>{`The following assets were released with this update:`}</p>
      <ul>
        <li parentName="ul">{`wd-applet-workday-byok`}</li>
        <li parentName="ul">{`wd-accent-workday-byok`}</li>
      </ul>
      <p>{`This release will not impact users as they are net-new assets with no changes to previously released
assets. For more information or to access these new icons in this update, please reference the
`}<a parentName="p" {...{
          "href": "https://ghe.megaleo.com/design/design-assets/"
        }}>{`Design Asset Repo.`}</a></p>
      <hr></hr>
      <h2 {...{
        "id": "canvas-on-deck-and-canvas-production-figma-library-deprecation"
      }}>{`Canvas On Deck and Canvas Production Figma Library Deprecation`}</h2>
      <p>{`The Canvas On Deck and Canvas Production libraries will be deprecated on `}<strong parentName="p">{`September 14th, 2022`}</strong>{`.
These libraries have not been maintained for some time and the components within those libraries are
no longer accurate or supported in code.`}</p>
      <p>{`These libraries have been moved to an archival project within the Canvas Figma team. This will not
immediately impact existing designs. However, users will no longer be able to search for additional
components in the On Deck or Production libraries once they are deprecated on September 14th.
Components from the On Deck or Production libraries that have already been pulled into a Figma file
will still show up as local components for that file, but `}<em parentName="p">{`it is highly recommended for teams to
replace those unsupported components with a supported version from the Standard Tooling or Canvas
Web libraries.`}</em></p>
      <p>{`A `}<a parentName="p" {...{
          "href": "https://www.figma.com/file/HgmEmtjEGFR9Tp654CVrtZ/Canvas-Deprecation-Map"
        }}>{`Canvas Deprecation Map`}</a>{`
has been created to help teams transition to using supported libraries along with migration guidance
that explains where things may be moving. Additional support will be available on the following
`}<a parentName="p" {...{
          "href": "/help/canvas-support/"
        }}>{`Canvas Design Office Hours sessions`}</a>{`:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`August 15th, European Office Hours:`}</strong>{` 10:30 AM to 11:00 AM GMT`}</li>
        <li parentName="ul"><strong parentName="li">{`August 15th, US Office Hours:`}</strong>{` 10:15 AM to 11:00 AM PST`}</li>
        <li parentName="ul"><strong parentName="li">{`August 24th, European Office Hours:`}</strong>{` 10:30 AM to 11:00 AM GMT`}</li>
        <li parentName="ul"><strong parentName="li">{`August 24th, US Office Hours:`}</strong>{` 10:15 AM to 11:00 AM PST`}</li>
      </ul>
      <p>{`The sessions will cover the supporting documents and best practice when it comes to replacing
components in your files with ones that are supported. These sessions will also be recorded.`}</p>
      <p>{`Check out our FAQs about the
`}<a parentName="p" {...{
          "href": "https://www.figma.com/file/HgmEmtjEGFR9Tp654CVrtZ/Canvas-Deprecation-Map?node-id=36%3A9306"
        }}>{`deprecation of these libraries here`}</a>{`.`}</p>
      <p>{`To further support the deprecation of the On Deck and Production libraries, 22 new components have
been added to the `}<strong parentName="p">{`📚UI Platform Web (Standard Tooling XO)`}</strong>{`. This update will not impact existing
designs, but teams will need to accept the change to start uptaking the new components.`}</p>
      <p>{`Canvas Office Hours are also available should you require time directly with the team for these
changes.`}</p>
      <hr></hr>
    </InternalContent>
    <h2 {...{
      "id": "canvas-kit-720-release"
    }}>{`Canvas Kit 7.2.0 Release`}</h2>
    <p>{`Canvas 7.2.0 is now available. In this update, we added a grid model to the collection system.`}</p>
    <p>{`This makes it easier to create two-dimensional UI components and support keyboard navigation and
accessibility (e.g. a color picker). We also fixed a bug in the navigation of grids where up/down
keys were accidentally reversed, and the wrapping navigation now supports non-rectangular grids of
items (e.g. a calendar).`}</p>
    <p>{`Please note that grid in this context references a two-dimensional list/table for developer use, not
our grid-based Canvas `}<a parentName="p" {...{
        "href": "/components/containers/layout"
      }}>{`Layout`}</a>{` component.`}</p>
    <p>{`To access this change, please upgrade to
`}<a parentName="p" {...{
        "href": "https://github.com/Workday/canvas-kit/releases/tag/v7.2.0"
      }}>{`Canvas Kit 7.2.0`}</a>{`.`}</p>
    <hr></hr>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "july-2022-figma-release"
      }}>{`July 2022 Figma Release`}</h2>
      <p>{`On July 20th, 2022, our Canvas Web Figma libraries will be updated to include the addition of
inverse variants for `}<a parentName="p" {...{
          "href": "https://canvas.workdaydesign.com/components/inputs/radio"
        }}>{`Radio buttons`}</a>{` and
`}<a parentName="p" {...{
          "href": "https://canvas.workdaydesign.com/components/inputs/checkbox"
        }}>{`Checkboxes`}</a>{` (part of Canvas Kit
7.0.7). We also will add an Action Bar variant for our Delete button.`}</p>
      <p><strong parentName="p">{`Inverse Radio Button and Checkboxes`}</strong></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/8fddf49cc2b843b4eb7526232ae165f4/339e3/inverse-radio.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "26.486486486486488%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAABCklEQVR42p2NPU/CUBSG+xcMJk4gakEjf8ugAxb5KuJk4uYmi8ikgxQHwqCJCmV2MDFhJZZ72UyrtBGQqtjX23vL4Opw8pzzvCfnSN+fE0zfXzD7GgGeC2/2Ae/HBRv+VdKj4eLk1kFVd1Bp2zht2ZyVtiNKFzwL+KcPWO04KN/ZeOhNIR1f21jYMrCeGyCepVhNU9Yz7lHIbJYzFCvM+VmUcSNPEMuKfO4S6gBLO30cNYf+wSEWtw2+tMaWwimCiEI45RzhLrpL+BPfJfLP/MGyIg77blOlCCX7OGy8QbrvTpA6N1GqWyjWXlG4tFCoWZz7GnOacHMe1E2oQV7UBEtXFpQLEzdPY/wCdREylP+Zp4IAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Screenshot of the inverse variant styling for Radio Buttons in Canvas Web Figma library.",
              "title": "Screenshot of the inverse variant styling for Radio Buttons in Canvas Web Figma library.",
              "src": "/static/8fddf49cc2b843b4eb7526232ae165f4/50383/inverse-radio.png",
              "srcSet": ["/static/8fddf49cc2b843b4eb7526232ae165f4/1efb2/inverse-radio.png 370w", "/static/8fddf49cc2b843b4eb7526232ae165f4/50383/inverse-radio.png 740w", "/static/8fddf49cc2b843b4eb7526232ae165f4/339e3/inverse-radio.png 977w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/7ca35b7537bb3871dced1825bc81df55/28b65/inverse-checkbox.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "33.78378378378378%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABRklEQVR42p2O30oCYRDFPzdJKzWpwMTXk4o0d61c07RHKKhX6MIk7QmC7DIq6t798y10UwYrCYmUp7O7CeJN0MWPmTmcOTPCfX3BcOAC4xHGX0Mf4JuM/4U4v+uj+eDhonHvoklaTwNczjDRWs9T/W9tU2s8fqD7NoIQWxYW9iWWShJKUSLKPrInMafZWGQfUu1A27WpWUjorEXbnz2fQl/yQEJsWzi56UN4ptWKxErF8QNipSA8rkus1xxEuZSuSy45SOoWMnXvAcffWS47vj9z5EAUbJx2GBjmtbUqDcQLSZQn2EgdMpxaqmryIEPLDKyZiOmO33tPxPlxuhZ8enbLQLFhYJ6DwgsiZ0HkA0IkrJm+FlENKDuc8waimkGN/rxJzUYoZyJS5E7WxPE1A7ONHrSrdxTaU7Rm6h+aSjYveuh0P/EDzQSRXdxR2DQAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Screenshot of the inverse variant styling for Checkboxes in Canvas Web Figma library.",
              "title": "Screenshot of the inverse variant styling for Checkboxes in Canvas Web Figma library.",
              "src": "/static/7ca35b7537bb3871dced1825bc81df55/50383/inverse-checkbox.png",
              "srcSet": ["/static/7ca35b7537bb3871dced1825bc81df55/1efb2/inverse-checkbox.png 370w", "/static/7ca35b7537bb3871dced1825bc81df55/50383/inverse-checkbox.png 740w", "/static/7ca35b7537bb3871dced1825bc81df55/28b65/inverse-checkbox.png 2414w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p>{`Inverse variants will be added to Radio Buttons and Checkboxes to handle use cases on darker
backgrounds.`}</p>
      <p><strong parentName="p">{`Action Bar Variant for Delete Button`}</strong></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/b3d2ce50a68d0bb147d8f476d7eb6fcb/38124/action-bar-delete-variant.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "42.972972972972975%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABoUlEQVR42o2SzU8TQRiH+z9yMcSDcsH4D5AQJRIuRuPJiyHGkzEakKJ8teDSbndr2X4BXViXdrtdSnfbhUKkJJSmaR9nK2iM9WOSZy4z7/P+JvOGEKtz2abX6/G/q9vp0D7/SvuiRa/b/eUsFGylVhPJc8ieuuTO/k3yxCHqVIgeOkhH1UGtd3l+LRTBPtQMppU80aqF7FvEGhbxIcgCqV7kuZlkRi4wHdOZWNOZTKRRxFngCtGH5ZrJM61AxDZR6haqbw8lKQikLy0N1TOR3SJPEwc81vJsnVRuEvYHwidbu0QqJlLVICYuKn75N1RBkPRFKcVHW2fjaJ9XOwYPlAypY/t7wv5VhyXX5KG6zaK1z6qjs3ZosNkoDSUQTunr3E3PM55dYDwT5lbqLcrPhLAqOs2tvEESXWPXRXKjPBSlYfNo7xNj2ffcy4W5n1/ktvYOVXzUD+FS7Yt4Qp7ZnEHYKpIU8eP1PwsnCxFGheROeo6xzDwjn18TD558Mzbli1MyzSqa75Jtuuyeeez8hcRxhQ2/hCQmIiBSP8BrtwbCb6+QepEzQIYfAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Screenshot of the Delete Action buttons styling in Canvas Web Figma library.",
              "title": "Screenshot of the Delete Action buttons styling in Canvas Web Figma library.",
              "src": "/static/b3d2ce50a68d0bb147d8f476d7eb6fcb/50383/action-bar-delete-variant.png",
              "srcSet": ["/static/b3d2ce50a68d0bb147d8f476d7eb6fcb/1efb2/action-bar-delete-variant.png 370w", "/static/b3d2ce50a68d0bb147d8f476d7eb6fcb/50383/action-bar-delete-variant.png 740w", "/static/b3d2ce50a68d0bb147d8f476d7eb6fcb/38124/action-bar-delete-variant.png 953w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p>{`A new Action Bar variant with the Delete Button will be added to help designers create mockups of
workflows that can be terminated or exited out of the flow.`}</p>
      <p>{`These enhancements will not impact existing designs. To uptake these changes, please accept the
updates in Figma.`}</p>
      <hr></hr>
    </InternalContent>
    <h2 {...{
      "id": "canvas-kit-710-release"
    }}>{`Canvas Kit 7.1.0 Release`}</h2>
    <p>{`Canvas 7.1.0 is now available. We enhanced our Tab component to support mobile behavior based on
modality. WebView Tabs will now switch from displaying an overflow menu to become scrollable from
left to right in order to replicate a mobile experience.`}</p>
    <p>{`To access this change, please upgrade to
`}<a parentName="p" {...{
        "href": "https://github.com/Workday/canvas-kit/releases/tag/v7.1.0"
      }}>{`Canvas Kit 7.1.0`}</a></p>
    <hr></hr>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "june-2022-design-asset-release"
      }}>{`June 2022 Design Asset Release`}</h2>
      <p>{`The Design Asset June 2022 Release is now available for use through the Canvas Assets Figma library
for designers and NPM, Artifactory, or SAS (on all environments) for developers.`}</p>
      <p>{`The following asset was released with this update:`}</p>
      <ul>
        <li parentName="ul">{`Wd-applet-manager-insights`}</li>
      </ul>
      <p>{`This release will not impact users as it is a net-new asset with no changes to previously released
assets.`}</p>
      <p>{`For more information on this update, please reference the
`}<a parentName="p" {...{
          "href": "https://ghe.megaleo.com/design/design-assets/"
        }}>{`Design Asset Repo`}</a></p>
      <hr></hr>
      <h2 {...{
        "id": "may-2022-figma-library-bug-fixes"
      }}>{`May 2022 Figma Library Bug Fixes`}</h2>
      <p>{`A Canvas Web Figma library update was released May 26th, 2022 with the following bug fixes:`}</p>
      <ul>
        <li parentName="ul">{`Updated a missing text variant for Checkboxes with left labels`}</li>
        <li parentName="ul">{`Fixed improper alert styling applied to error state Checkboxes with top labels`}</li>
      </ul>
      <p>{`Users will now be able to access the missing text variant for left label Checkboxes and see the top
label Checkboxes with the correct error styling. This update will not impact existing designs.`}</p>
      <p>{`To uptake these bug fixes, please accept the updates in Figma. If you have any questions or
concerns, please sign-up for one of our
`}<a parentName="p" {...{
          "href": "/help/canvas-support#live-support"
        }}>{`Office Hours`}</a>{`, thank you!`}</p>
      <hr></hr>
    </InternalContent>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "canvas-kit-v7-and-figma-library-release"
      }}>{`Canvas Kit v7 and Figma Library Release`}</h2>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <h2 {...{
        "id": "canvas-kit-v7-release"
      }}>{`Canvas Kit v7 Release`}</h2>
    </ExternalContent>
    <InternalContent mdxType="InternalContent">
      <p>{`Canvas Kit v7 will be released on `}<strong parentName="p">{`May 18th, 2022`}</strong>{`, alongside updates to the Canvas Web and Canvas
Token Figma libraries to reflect the v7 changes. V7 is one of our larger releases – jam packed with
new accessibility features, components, and more! An upgrade guide will be available once v7 goes
live to assist product teams with their migration to the newest Canvas Kit version.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`Canvas Kit v7 will be released on `}<strong parentName="p">{`May 18th, 2022`}</strong>{`. V7 is one of our larger releases – jam packed
with new accessibility features, components, and more! An upgrade guide will be available once v7
goes live to assist product teams with their migration to the newest Canvas Kit version.`}</p>
    </ExternalContent>
    <InternalContent mdxType="InternalContent">
      <h3 {...{
        "id": "release-overview"
      }}>{`Release Overview`}</h3>
      <p>{`The table below contains a high-level overview of the updates that will be rolled out as part of the
v7 release. The impact for designers and developers are defined as follows:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`None`}</strong>{`: inapplicable to the role or no actions are required for users to adopt the change;
Updates will be applied automatically once users accept the changes in Figma and/or upgrade to
Canvas Kit v7`}</li>
        <li parentName="ul"><strong parentName="li">{`Low`}</strong>{`: minor changes are required for users to adopt the change`}</li>
        <li parentName="ul"><strong parentName="li">{`Medium`}</strong>{`: a moderate amount of changes are required for users to adopt the change, such as
switching out UI elements`}</li>
        <li parentName="ul"><strong parentName="li">{`High`}</strong>{`: a large amount of changes are required for users to adopt the change, requiring product
teams to make major design or development decisions`}</li>
      </ul>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Change`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Short Description`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Designer Impact`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Developer Impact`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Emotion 11, React 17, Typescript 4.1 Upgrade`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Upgrading to Emotion 11 and React 17 as part of v7`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`High`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Depth Token Upgrades`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`All depth tokens will include a second shadow along with minor changes to the opacity and length/size of the shadow`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Icon Button Deprecation & Consolidation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Icon Buttons will be deprecated and replaced with icon only Primary, Secondary, and Tertiary button variants`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`High`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`High`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Page Header, Main Header, and Cookie Banner Hard Deprecation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Soft deprecation of Page Header, Main Header, and Cookie Banner from v6 will be hard deprecated in v7, with no further access in Canvas Kit`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`High`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`High`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Built-in Tooltip for Side Panel`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Side Panel component will now have a tooltip component built directly into it for accessibility`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Built-in Overflow Behavior for Action Bar`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Action Bar component will have overflow behavior built into it`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Multidimensional and Hierarchical Collection`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New Menu component will be added to main package along with Multidimensional and Hierarchical Collection`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Pills Component`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new Pills component will be released in v7 that can be used to display short labels`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New External Hyperlink Component`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new External Hyperlink component will be available in v7 and should be used for navigational links that bring users outside of the current application or domain`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Layout Styles`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New layout styles will be available for use in Figma to replicate grids`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Max Width Increase for Status Indicator`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Max width for the Status Indicator component will be increased to 200px from the original 150px to avoid truncated text`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Compound Component Conversion`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Banner will be converted to a compound component. Radio, Checkbox, and Toast will be converted into compound components as a minor release (after May 18th, 2022).`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`WebView Tabs`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Tabs will be enhanced to support mobile behavior based on modality as a minor release (after May 18th, 2022)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Component Promotion`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Box, Flex, and Stack will become part of the Main package in v7`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
        </tbody>
      </table>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <h3 {...{
        "id": "release-overview-1"
      }}>{`Release Overview`}</h3>
      <p>{`The table below contains a high-level overview of the updates that will be rolled out as part of the
v7 release. The impact for users are defined as follows:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`None`}</strong>{`: inapplicable to the role or no actions are required for users to adopt the change;
Updates will be applied automatically once users upgrade to Canvas Kit v7`}</li>
        <li parentName="ul"><strong parentName="li">{`Low`}</strong>{`: minor changes are required for users to adopt the change`}</li>
        <li parentName="ul"><strong parentName="li">{`Medium`}</strong>{`: a moderate amount of changes are required for users to adopt the change, such as
switching out UI elements`}</li>
        <li parentName="ul"><strong parentName="li">{`High`}</strong>{`: a large amount of changes are required for users to adopt the change, requiring product
teams to make major design or development decisions`}</li>
      </ul>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Change`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Short Description`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Developer Impact`}</th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Emotion 11, React 17, Typescript 4.1 Upgrade`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Upgrading to Emotion 11 and React 17 as part of v7`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`High`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Depth Token Upgrades`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`All depth tokens will include a second shadow along with minor changes to the opacity and length/size of the shadow`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Icon Button Deprecation & Consolidation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Icon Buttons will be deprecated and replaced with icon only Primary, Secondary, and Tertiary button variants`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`High`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Page Header, Main Header, and Cookie Banner Hard Deprecation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Soft deprecation of Page Header, Main Header, and Cookie Banner from v6 will be hard deprecated in v7, with no further access in Canvas Kit`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`High`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Built-in Tooltip for Side Panel`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Side Panel component will now have a tooltip component built directly into it for accessibility`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Built-in Overflow Behavior for Action Bar`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Action Bar component will have overflow behavior built into it`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Multidimensional and Hierarchical Collection`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New Menu component will be added to main package along with Multidimensional and Hierarchical Collection`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Pills Component`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new Pills component will be released in v7 that can be used to display short labels`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New External Hyperlink Component`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new External Hyperlink component will be available in v7 and should be used for navigational links that bring users outside of the current application or domain`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Max Width Increase for Status Indicator`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Max width for the Status Indicator component will be increased to 200px from the original 150px to avoid truncated text`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Compound Component Conversion`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Banner will be converted to a compound component. Radio, Checkbox, and Toast will be converted into compound components as a minor release (after May 18th, 2022).`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`WebView Tabs`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Tabs will be enhanced to support mobile behavior based on modality as a minor release (after May 18th, 2022)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Component Promotion`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Box, Flex, and Stack will become part of the Main package in v7`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
        </tbody>
      </table>
    </ExternalContent>
    <h3 {...{
      "id": "emotion-11-react-17-and-typescript-41-upgrade"
    }}>{`Emotion 11, React 17, and Typescript 4.1 Upgrade`}</h3>
    <p>{`We will be upgrading to Emotion 11 and React 17 as part of the v7 release. This will allow teams to
upgrade to newer versions of Emotion and React without the need to maintain older versions in order
to use Canvas Kit.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This upgrade will not impact other Canvas sub-products like the Figma libraries or the Design Asset
repo.`}</p>
    </InternalContent>
    <p>{`As part of this upgrade, Canvas Kit will remove the jsx pragma and the use of CSS props. However,
consumers can continue to use CSS props.`}</p>
    <p>{`To use v7, teams will need to be using:`}</p>
    <ul>
      <li parentName="ul">{`React 16.14 OR React 17.X for backwards compatibility on JSX transform if they are using Babel or
Typescript to compile code`}</li>
      <li parentName="ul">{`Emotion 11`}</li>
      <li parentName="ul">{`Typescript 4.1 or higher, if applicable`}</li>
      <li parentName="ul">{`Babel 7.9 or higher, if applicable`}</li>
      <li parentName="ul">{`An Enzyme adapter for React 17, if applicable`}</li>
    </ul>
    <p>{`Please note that teams may also need to update any side effect dependencies or linting packages with
this update.`}</p>
    <p>{`View our `}<a parentName="p" {...{
        "href": "https://github.com/Workday/canvas-kit/discussions/1455"
      }}>{`React 17`}</a>{` and
`}<a parentName="p" {...{
        "href": "https://github.com/Workday/canvas-kit/discussions/1453"
      }}>{`Emotion 11`}</a>{` Github discussions to learn
more about any gotchas or tips and tricks with these upgrades. And of course, feel free to
contribute to the discussion with any questions or learnings of your own!`}</p>
    <h3 {...{
      "id": "depth-token-updates"
    }}>{`Depth Token Updates`}</h3>
    <p>{`Our Depth tokens will be getting a major update in v7 to enhance the look and feel of Canvas
components.`}</p>
    <p>{`All depth tokens will be updated to include a second shadow along with some minor changes to the
opacity and length/size of the shadow. Depth -1 will be deprecated and two new depth values, Depth 5
and Depth 6, will be introduced in v7. As part of the depth updates, the following components will
repoint to the new depth tokens:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Select Menu, Menu, Breadcrumbs Dropdown Menu`}</strong>{`: Depth 2 → Depth 3`}</li>
      <li parentName="ul"><strong parentName="li">{`Color Picker Palette, Toast, Popup, Dialog`}</strong>{`: Depth 2 → Depth 5`}</li>
      <li parentName="ul"><strong parentName="li">{`Card`}</strong>{`: Depth 2 → Depth 1`}</li>
      <li parentName="ul"><strong parentName="li">{`Side Panel (alternate variant without overlay)`}</strong>{`: Depth 3 → Depth 5`}</li>
      <li parentName="ul"><strong parentName="li">{`Side Panel (alternate variant with overlay)`}</strong>{`: Depth 3 → Depth 6`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal`}</strong>{`: Depth 2 → Depth 6`}</li>
      <li parentName="ul"><strong parentName="li">{`Combobox`}</strong>{`: Depth 1 → Depth 3`}</li>
    </ul>
    <InternalContent mdxType="InternalContent">
      <p>{`Teams using Depth -1 will need to manually update impacted components in Figma and code to one of
the supported Depth tokens, ideally in alignment with Canvas recommendations. More information about
the usage guidance for Depth tokens can be found on our `}<a parentName="p" {...{
          "href": "/styles/tokens/depth"
        }}>{`Depth documentation`}</a>{`.`}</p>
      <p>{`Components will automatically be updated to the new depth values in Figma once users accept the
Figma changes, but any changes made to the depth tokens of a component will continue to persist. For
example, the Card component is currently using a Depth 2 default value. If the depth value on a card
was modified to point to Depth 3 instead, the Card will continue to point to the Depth 3 token
instead of the new default depth token value (Depth 1) for that card instance, but it will adopt the
v7 changes to that specific depth token of Depth 3.`}</p>
      <p>{`All other changes will be reflected automatically once users accept the changes in Figma and/or
upgrade to Canvas Kit v7.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`Teams using Depth -1 will need to manually update impacted components in code to one of the
supported Depth tokens, ideally in alignment with Canvas recommendations. More information about the
usage guidance for Depth tokens can be found on our `}<a parentName="p" {...{
          "href": "/styles/tokens/depth"
        }}>{`Depth documentation`}</a>{`.`}</p>
      <p>{`All other changes will be reflected automatically once users upgrade to Canvas Kit v7.`}</p>
    </ExternalContent>
    <h3 {...{
      "id": "icon-button-deprecation-and-consolidation"
    }}>{`Icon Button Deprecation and Consolidation`}</h3>
    <p>{`Icon Buttons will be deprecated and replaced with icon only Primary, Secondary, and Tertiary button
variants to consolidate the number of Canvas button variants.`}</p>
    <p><strong parentName="p">{`Icon Button Mapping`}</strong>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1fc5d859b744a7ae868ae51370bc65e2/37114/v7-Icon-Button-Mapping.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.486486486486484%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "The mapping of Icon Buttons to icon only Secondary and Tertiary button variants. Circle Icon Buttons will map to Tertiary icon only variants, Circle-Filled Icon Buttons will map to Secondary icon only variants, Inverse Icon Buttons will map to Tertiary Inverse icon only variants, and Inverse-Filled Icon Buttons will map to Secondary Inverse icon only variants. Plain, Square, and Square-Filled Icon Buttons will no longer be supported.",
            "title": "The mapping of Icon Buttons to icon only Secondary and Tertiary button variants. Circle Icon Buttons will map to Tertiary icon only variants, Circle-Filled Icon Buttons will map to Secondary icon only variants, Inverse Icon Buttons will map to Tertiary Inverse icon only variants, and Inverse-Filled Icon Buttons will map to Secondary Inverse icon only variants. Plain, Square, and Square-Filled Icon Buttons will no longer be supported.",
            "src": "/static/1fc5d859b744a7ae868ae51370bc65e2/50383/v7-Icon-Button-Mapping.png",
            "srcSet": ["/static/1fc5d859b744a7ae868ae51370bc65e2/1efb2/v7-Icon-Button-Mapping.png 370w", "/static/1fc5d859b744a7ae868ae51370bc65e2/50383/v7-Icon-Button-Mapping.png 740w", "/static/1fc5d859b744a7ae868ae51370bc65e2/37114/v7-Icon-Button-Mapping.png 2300w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`As part of this consolidation, the following changes will be made:`}</p>
    <ul>
      <li parentName="ul">{`Icon Buttons will no longer be supported as a standalone component. Instead, they will be replaced
with icon only Primary, Secondary, and Tertiary button variants`}</li>
      <li parentName="ul">{`The Icon Button Toggle state and Toggled prop will not be supported in the new icon only button
variants. However, it will still be available for the Toolbar Icon Button`}</li>
      <li parentName="ul">{`The Icon Buttons used in Action Bar, Side Panel, Color Picker, Search Form, Breadcrumbs,
Pagination, Modal, and Toast will all be updated to point to the new icon only button variants`}</li>
      <li parentName="ul">{`Segmented Control will be converted into a compound component`}</li>
      <li parentName="ul">{`The dataLabel prop will be removed and the data Primary and Primary Inverse button variants will
no longer be supported`}</li>
      <li parentName="ul">{`The medium sized Icon Buttons with a 24x icon that are being mapped to the new medium sized icon
only Primary, Secondary, and Tertiary button variants will have a smaller 20px icon`}</li>
    </ul>
    <InternalContent mdxType="InternalContent">
      <p>{`Designers using Canvas Icon Buttons should transition to using the new icon only Primary, Secondary,
and Tertiary button variants for any current and future designs to ensure a smooth
design-to-development hand off. Prexisting changes, such as icon or color customizations, made to
components with Icon Buttons (Action Bar, Side Panel, Color Picker, etc.) will not persist and
transfer over to the new icon only button variants. Additionally, components using configured states
(active, hover, disabled, etc.) will be reverted to the default state. Designers will need to
manually update the component states and the new icon only button variant in those components to
their desired configurations.`}</p>
    </InternalContent>
    <p>{`A code mod will be provided to automatically update existing Icon Buttons in product to their new
icon only Secondary and Tertiary button variants.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`Teams using:`}</p>
      <ul>
        <li parentName="ul">{`Plain, Square, or Square-Filled Icon Buttons should either transition to the new icon only
Primary, Secondary, and Tertiary button variants, the Toolbar Icon Button, or the Segmented
Control component`}</li>
        <li parentName="ul">{`The Toggled Icon Button state should opt to use the icon only Primary button variant instead to
represent toggle on and the icon only Tertiary button variant for toggle off in Figma. Since the
Toggle prop will no longer be available for developers to use, togglable icon only buttons will
require additional development work to implement. More information will be provided in our v7
usage guide to help developers implement togglable icon only buttons with using a new low level
BaseButton component`}</li>
        <li parentName="ul">{`The dataLabel prop or the data Primary and Primary Inverse button variants in the Canvas Web Figma
library will need to manually add a styled span to their code and use the icon left Primary and
Primary Inverse button variants in Canvas Web to replicate the data Primary and Primary Inverse
button variants`}</li>
      </ul>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`Teams using:`}</p>
      <ul>
        <li parentName="ul">{`Plain, Square, or Square-Filled Icon Buttons should either transition to the new icon only
Primary, Secondary, and Tertiary button variants, the Toolbar Icon Button, or the Segmented
Control component`}</li>
        <li parentName="ul">{`The Toggle prop will need to implement a workaround, but more information will be provided in our
v7 usage guide to help developers create togglable icon only buttons`}</li>
        <li parentName="ul">{`The dataLabel prop will need to manually add a styled span to their code to replicate the data
Primary and Primary Inverse button variants`}</li>
      </ul>
    </ExternalContent>
    <h3 {...{
      "id": "page-header-main-header-and-cookie-banner-hard-deprecation"
    }}>{`Page Header, Main Header, and Cookie Banner Hard Deprecation`}</h3>
    <p>{`In v6, we soft deprecated Page Header, Main Header (also known as Global Header), and Cookie Banner
by adding deprecation notices across all Canvas sub-products. These components will be hard
deprecated in v7, meaning users will no longer have access to them in Canvas Kit.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This hard deprecation will not impact existing designs, but they will no longer show up on the Figma
assets panel. However, newer versions of the Page Header and Main Header and are now available in
the UI Platform Web (Standard Tooling XO) Figma library for designers to use. The Main Header has
also been added to the Application Chrome repo for internal Workday teams to use.`}</p>
    </InternalContent>
    <p>{`Examples have also been created for all three components in Storybook to help developers create
their own Page Header, Main Header, and Cookie Banner using existing Canvas Kit components. To
continue using any of the previously mentioned components, developers should leverage the Storybook
examples to implement them in code.`}</p>
    <h3 {...{
      "id": "built-in-tooltip-for-side-panel"
    }}>{`Built-in Tooltip for Side Panel`}</h3>
    <p>{`The Side Panel component will now have a tooltip component built directly into it to help bake in
accessibility into everything that we do.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`As part of this release, the Side Panel component will now be available in the Canvas Web Figma
library for designers to use. A Side Panel variant with the built-in tooltip will also be available
to help designers indicate the presence of the tooltip text in their designs.`}</p>
    </InternalContent>
    <p>{`Teams that have already manually implemented a tooltip for their Side Panel components will need to
remove it and utilize the built-in tooltip to uptake this change.`}</p>
    <h3 {...{
      "id": "built-in-overflow-behavior-for-action-bar"
    }}>{`Built-in Overflow Behavior for Action Bar`}</h3>
    <p>{`The Action Bar component will have overflow behavior built into it. With the Icon Button
consolidation, the Icon Button in Action Bar will also be replaced with a new icon only Secondary
button variant. When clicked, that icon only Secondary button variant will open up an overflow
drop-down menu.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`As part of this enhancement, Action Bar will also be converted into a component with variants in the
Canvas Web library, making it available in the Figma assets panel for designers to directly search
for and pull from. Existing designs will not be impacted by this change. However, designers should
manually update active designs to use the new Action Bar component in order to ensure a smooth
developer handoff and consistency between design and code.`}</p>
    </InternalContent>
    <p>{`Teams that have manually implemented overflow behavior into Action Bar will need to remove it and
utilize the new built-in overflow behavior to uptake this change. No changes are required to uptake
this change if overflow behavior has not been manually implemented for Action Bar in code.`}</p>
    <h3 {...{
      "id": "new-multidimensional-and-hierarchical-collection"
    }}>{`New Multidimensional and Hierarchical Collection`}</h3>
    <p>{`A new multidimensional and hierarchical collection will be rolled out in v7, along with a new Menu
component in the Main package. No other components will be impacted by the new multidimensional and
hierarchical collection.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This will not result in any visual changes and will not impact existing designs.`}</p>
    </InternalContent>
    <p>{`As part of this change, a new Menu component will be introduced in the Main package. Teams using the
Menu in the Preview package should start migrating over to the Menu component in Main as the Preview
Menu will be deprecated in the next few major releases.`}</p>
    <h3 {...{
      "id": "new-pills-component"
    }}>{`New Pills Component`}</h3>
    <p>{`A new Pills component will be released in v7 that can be used to display short labels and can be
non-interactive, removable, or additive with the option to display an avatar.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`Pills will be available on Canvas Web once v7 is released and documentation will also be available
to help teams utilize Pills. This net new component will not impact existing designs and there is no
action required from developers to uptake this change in v7`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`No action will be required for developers to uptake this change in v7, but documentation will be
available on the Canvas Design System site to help teams utilize Pills.`}</p>
    </ExternalContent>
    <h3 {...{
      "id": "new-external-hyperlink-component"
    }}>{`New External Hyperlink Component`}</h3>
    <p>{`A new External Hyperlink component will also be available in v7 and should be used for navigational
links that bring users outside of the current application or domain. This component is similar to
our existing Hyperlink component, but will include the extLink System Icon on the right-hand size to
indicate that the link is outside of the current application or domain.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`In Figma, ExternalHyperlink components are represented as text styling. To replicate this component,
designers will need to:`}</p>
      <ul>
        <li parentName="ul">{`Highlight the ExternalHyperlink text and select one of the link text-style (ex: Body S link -
(500) Regular, Body M link - (500) Regular). Please note that the ExternalHyperlink text is not
recommended for titles, headings, or small and medium subtexts`}</li>
        <li parentName="ul">{`Highlight the ExternalHyperlink text again and select the Blueberry 400 color`}</li>
        <li parentName="ul">{`Add the extLink icon to the right of the text. The icon scale will vary based on the text size.
View the specs to learn more about icon sizing.`}</li>
      </ul>
      <p>{`This net new component will not impact existing designs and there is no action required from
developers to uptake this change in v7.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`No action will be required for developers to uptake this change in v7.`}</p>
    </ExternalContent>
    <InternalContent mdxType="InternalContent">
      <h3 {...{
        "id": "new-layout-styles"
      }}>{`New Layout Styles`}</h3>
      <p>{`Designers will now have access to new layout styles on Figma to replicate grids in product designs.
To access the layout styles, designers should:`}</p>
      <ul>
        <li parentName="ul">{`Turn on the Canvas Web library`}</li>
        <li parentName="ul">{`Create a new frame or select an existing frame`}</li>
        <li parentName="ul">{`Open the Layout Grid panel in Figma`}</li>
        <li parentName="ul">{`Open up the styles and select one of the layout styles (Small, Medium, Large, and Extra-Large)
under “Canvas Web”`}</li>
      </ul>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "476px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/ab58d77d3c68370d6f8eb50c63c3faf1/f2205/v7-Layout-Grid.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "96.75675675675674%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Screenshot of the Figma Layout Grid panel with the new layout styles listed under Canvas Web",
              "title": "Screenshot of the Figma Layout Grid panel with the new layout styles listed under Canvas Web",
              "src": "/static/ab58d77d3c68370d6f8eb50c63c3faf1/f2205/v7-Layout-Grid.png",
              "srcSet": ["/static/ab58d77d3c68370d6f8eb50c63c3faf1/1efb2/v7-Layout-Grid.png 370w", "/static/ab58d77d3c68370d6f8eb50c63c3faf1/f2205/v7-Layout-Grid.png 476w"],
              "sizes": "(max-width: 476px) 100vw, 476px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p>{`This update will not impact existing designs and there is no action required from developers to
uptake this change in v7.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "max-width-increase-for-status-indicator"
    }}>{`Max Width Increase for Status Indicator`}</h3>
    <p>{`The max width for the Status Indicator component will be increased to 200px from the original 150px
to help teams minimize the need to truncate text. While it is recommended for the Status Indicator
text to be short and concise, a new prop called maxWidth will be introduced to allow max width to be
configurable. A Storybook example will also be provided to help teams implement tooltips for Status
Indicators with truncated text.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This change will not impact existing designs. However, Figma does not support max width capabilities
so designers will need to keep the max width constraint in mind when using the Status Indicator
component in their designs.`}</p>
    </InternalContent>
    <p>{`No action will be required for developers to uptake this change in v7.`}</p>
    <h3 {...{
      "id": "compound-component-conversion"
    }}>{`Compound Component Conversion`}</h3>
    <p>{`Several components will be converted into a compound component, making it easier for developers to
use and customize them at the sub-component level. The following components will be promoted to a
compound component in v7:`}</p>
    <ul>
      <li parentName="ul">{`Banner`}</li>
      <li parentName="ul">{`Radio (delayed release, will be available in a minor v7 release after May 18th, 2022)`}</li>
      <li parentName="ul">{`Checkbox (delayed release, will be available in a minor v7 release after May 18th, 2022)`}</li>
      <li parentName="ul">{`Toast (delayed release, will be available in a minor v7 release after May 18th, 2022)`}</li>
    </ul>
    <InternalContent mdxType="InternalContent">
      <p>{`This will not result in any visual changes and will not impact existing designs.`}</p>
    </InternalContent>
    <p>{`No action will be required for developers to uptake this change in v7.`}</p>
    <h3 {...{
      "id": "webview-tabs"
    }}>{`WebView Tabs`}</h3>
    <p>{`Tabs will be enhanced to support mobile behavior based on modality. WebView Tabs will switch from
displaying an overflow menu to become scrollable from left to right in order to replicate a mobile
experience. This feature has been delayed and will be available in a minor v7 release after May
18th, 2022.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This will not result in any visual changes and will not impact existing designs.`}</p>
    </InternalContent>
    <p>{`No action will be required for developers to uptake this change in v7.`}</p>
    <h3 {...{
      "id": "component-promotion"
    }}>{`Component Promotion`}</h3>
    <p>{`The following component will be promoted to the Main package as part of the v7 release:`}</p>
    <ul>
      <li parentName="ul">{`Box`}</li>
      <li parentName="ul">{`Flex`}</li>
      <li parentName="ul">{`Stack`}</li>
    </ul>
    <InternalContent mdxType="InternalContent">
      <p>{`This will not result in any visual changes and will not impact existing designs.`}</p>
    </InternalContent>
    <p>{`No action will be required for developers to uptake this change in v7.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      